import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState, useRef } from "react";
import { loader, db } from "../../firebase/config";
import { doc, getDocFromCache, getDoc, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { TokenInfoPopup } from "../../components/tokenInfoPopup";

const auth = getAuth();

const calculateDaysLeft = (purchaseDate, months) => {
    const endDate = new Date(purchaseDate.toDate()); // Create a new Date object for end_date
    endDate.setMonth(endDate.getMonth() + months); // Add the number of months to the purchase date
    // Ensure the endDate does not fall before the current date
    const now = new Date();
    if (endDate < now) {
        return 0;
    }
    // Calculate the difference in days
    const timeDiff = endDate - now;
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
};

export const Track = () => {
    const [user, setUser] = useState({ id: auth.currentUser?.uid, tokenIDs: null });
    const [tokenList, setTokenList] = useState([]);
    const [aggregatedTokenArray, setAggregatedTokenArray] = useState([]);
    const [popupActive, setPopupActive] = useState(false);
    const [tokenSelected, setTokenSelected] = useState(null);
    const mapRef = useRef(null);
    const navigate = useNavigate();

     // get the current user and their tokens
     useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                let userDoc = await getDoc(doc(db, "users", authUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUser({id: authUser.uid, email: userData.email, tokenIDs: userData.tokenIDs, location: userData.location});
                } else {
                    navigate("/login"); // Redirect to login if no user is found
                }
            } else {
                navigate("/login"); // Redirect to login if no user is authenticated
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    // get list of tokens and their details and store in tokensList
    useEffect(() => {
        if (user.id) { // Ensure user.id is defined
            const unsubscribe = onSnapshot(doc(db, "users", user.id), async (snapshot) => {
                const tokenIDs = snapshot.data()?.tokensID;
                console.log("Data from Firestore:", tokenIDs);

                if (tokenIDs) { // Ensure tokenIDs is defined
                    const fetchTokens = async (tokenIDs) => {
                        const tokens = await Promise.all(tokenIDs.map(async (tokenID) => {
                            const tokenDoc = await getDoc(doc(db, "tokens", tokenID));
                            const token = tokenDoc.data();

                            if (token) {
                                const partnerDoc = await getDoc(doc(db, "partners", token.partner_id));
                                const partner = partnerDoc.data();
                                if (partner) {
                                    return { ...token, partner };
                                }
                            }
                            
                            return null; // Return null if either token or partner is not found
                        }));

                        // Filter out any null values from the result
                        return tokens.filter(token => token !== null);
                    };
                    await fetchTokens(tokenIDs).then((tokens) => setTokenList(tokens));
                }
            });
            return () => unsubscribe(); // Cleanup the listener on component unmount
        }
    }, [user.id]);

    // Aggregate tokens by partner_id
    useEffect(() => {
        const aggregatedTokens = tokenList.reduce((acc, token) => {
            const { partner_id, subscription_type, purchase_date } = token;
            const months = subscription_type === '1 Month Subscription' ? 1 : subscription_type === '3 Month Subscription' ? 3 : 0;

            if (acc[partner_id]) {
                // Update existing entry: increment count and add subscription days
                if (months) {
                    const days_left = calculateDaysLeft(purchase_date, months);
                    acc[partner_id].subscription_left += days_left;
                }
                acc[partner_id].count += 1;
            } else {
                // Create a new entry: initialize count and subscription days left
                const days_left = months ? calculateDaysLeft(purchase_date, months) : 0;
                acc[partner_id] = {
                    ...token,
                    count: 1,
                    subscription_left: days_left
                };
            }
            return acc;
        }, {});

        // Convert the aggregated tokens object to an array
        setAggregatedTokenArray(Object.values(aggregatedTokens));
        // loadMap();
        console.log("aggregated", aggregatedTokenArray)
    }, [tokenList]);

    // // render the google map with the markers and info windows
    // const loadMap = () => {
    //     if (!mapRef.current) { // if no map has been rendered yet and the user DOES have pickup tokens
    //         loader.load().then(async (google) => {
    //             //import google api libraries
    //             const mapOptions = { center: { lat: 41.310307, lng: -72.926985 },
    //                                  zoom: 16,
    //                                  mapId: "4504f8b37365c3d0",
    //                                  disableDefaultUI: true };
    //             const { Map } = await google.maps.importLibrary("maps");
    //             const { Geocoder } = await google.maps.importLibrary("geocoding");
    //             const map = new Map(document.getElementById("map"), mapOptions);
    //             mapRef.current = { map, google };
    //             const infoWindow = new google.maps.InfoWindow();
    //             const geocoder = new Geocoder();

    //             if (map && aggregatedTokenArray.length > 0) {
    //                 // for each partner in partnerList, geocode its address (to get coords) and draw a marker + create infowindow listener
    //                 aggregatedTokenArray.forEach((token) => {
    //                     geocoder.geocode({ address: token.partner?.address }, (results, status) => {
    //                         // if the geocode is successful
    //                         if (status === 'OK') {
    //                             // create a marker on the map
    //                             const marker = new google.maps.Marker({
    //                                 map,
    //                                 position: results[0].geometry.location,
    //                                 title: token.partner?.name,
    //                                 icon: {
    //                                     url: "/pin.png",
    //                                     scaledSize: new google.maps.Size(38, 58),
    //                                     origin: new google.maps.Point(0, 0),
    //                                     anchor: new google.maps.Point(16, 32)
    //                                 }
    //                             });
    //                             // add a listener for the infowindow to appear when the marker is clicked
    //                             marker.addListener("click", () => {
    //                                 infoWindow.setContent(`X packages ready for pickup`);
    //                                 infoWindow.setHeaderContent(token.partner?.name);
    //                                 infoWindow.open(map, marker);
    //                             });
    //                         //if the geocode is NOT successful
    //                         } else {
    //                             console.log('error with an address geocode');
    //                         }
    //                     });
    //                 });
    //             }
    //         });
    //     }
    // };

    // if this function is called, open the info sidebar popup for the selected business
    const handleInfoPopup = (token) => {
        const fetchData = async () => {
            setTokenSelected(token);
        };
        fetchData().then(() => setPopupActive(true));
    };

    return (
        <div>
            <div className="flex mt-28 justify-center space-x-10 mx-32 h-[500px] mb-20">
                {/* <div id="map" className="grow h-full border rounded-md shadow-md">
                </div> */}
                <div className="flex w-[450px] flex-col space-y-3">
                    <div className="flex flex-col h-full">
                        <p className="text-lg w-full py-1 text-center bg-red-1 text-white rounded-t-md">Your Pickup Tokens</p>
                        <div className="flex flex-col py-8 space-y-3 items-center w-full overflow-y-auto h-full px-8 border border-gray-300 shadow-md drop-shadow-md rounded-b-md">
                            {aggregatedTokenArray.map((token) => (
                                <button onClick={() => handleInfoPopup(token)} key={token.partner_id} className="flex flex-col h-20 w-full rounded-lg items-center border border-gray-300 shadow-[0_2px_4px_rgba(0,0,0,0.1)] hover:-translate-y-1 hover:shadow-lg focus:-translate-y-1 focus:shadow-lg justify-center p-2 bg-white text-black transition-all duration-300">
                                    <p className="text-sm font-semibold mb-1">{token.partner?.business_name}</p>
                                    <p className="text-xs italic text-gray-3">Shipping Address: {token.partner?.street_name1} {token.partner?.street_name2}, {token.partner?.postal_code.toString().padStart(5, '0')}</p>
                                    {token.subscription_type === "Pay-Per-Package" && <p className="text-xs italic text-gray-3">You have {token.count} pickup token{token.count > 1 && "s"}</p>}
                                    {token.subscription_type !== "Pay-Per-Package" && <p className="text-xs italic text-gray-3">You have unlimited pickup tokens for {token.subscription_left} days</p>}
                                </button>
                            ))}
                            {aggregatedTokenArray.length < 1 &&
                                <div className="flex flex-col h-20 w-full rounded-lg items-center border border-gray-300 shadow-[0_2px_4px_rgba(0,0,0,0.1)] justify-center p-2 bg-white text-black transition-all duration-300">
                                    <p className="text-sm font-semibold mb-1">You don't have tokens!</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <TokenInfoPopup token={tokenSelected} popupActive={popupActive} setPopupActive={setPopupActive} userID={user.id} />
        </div>
    );
};
