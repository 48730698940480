import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth"; // Import Firebase authentication
import { PartnerSettingsPopup } from "./partnerSettingsPopup";

let PAGES = {
    PICKUPS: "pickups",
    INFO: "info"
}

export const PartnerHeader = ({ currentPage }) => {
    const [page, setPage] = useState(currentPage);
    const [popupActive, setPopupActive] = useState(false);
    const navigate = useNavigate();

    const togglePage = () => {
        if (page === PAGES.PICKUPS) {
            setPage(PAGES.INFO);
            navigate("/info");
        } else {
            setPage(PAGES.PICKUPS);
            navigate("/pickups");
        }
    }

    return (
        <>  
            <div className="z-10 fixed flex top-0 w-full h-10 items-center bg-red-2 px-4 justify-between">
                <div className="flex ml-2">
                    <button onClick={() => navigate("/")} className="text-white font-medium text-[21px]">PickPackGo</button>
                </div>
                <div className="flex items-center">
                    {/* Switch */}
                    <div className="relative w-60 h-[27px] bg-white text-white rounded-full p-[2px] mr-1 text-base">
                        <div className={`absolute top-[2px] h-[23px] w-[49%] bg-red-2 rounded-full transition-transform duration-300 ${page === PAGES.PICKUPS ? 'translate-x-0' : 'translate-x-full'}`}></div>
                        <button onClick={() => togglePage()} className={`absolute mb-1 top-0 left-0 w-1/2 h-full text-center font-semibold transition-colors duration-300 ${page === PAGES.PICKUPS ? 'text-white' : 'text-black'}`}>
                            Pickups
                        </button>
                        <button onClick={() => togglePage()} className={`absolute mb-1 top-0 right-0 w-1/2 h-full text-center font-semibold transition-colors duration-300 ${page === PAGES.INFO ? 'text-white' : 'text-black'}`}>
                            Your Info
                        </button>
                    </div>
                </div>
            </div>
            
            <PartnerSettingsPopup popupActive={popupActive} setPopupActive={setPopupActive} />

            <Outlet/>
        </>
    )
}
