import { useEffect, useState, memo } from "react";
import { LocationInfoPopup } from "./locationInfoPopup";

export const TokenInfoPopup = memo(({ token, popupActive, setPopupActive, userID }) => {
    const [locationPopupActive, setLocationPopupActive] = useState(false);
    const [state, setState] = useState("info");
    const [pickupCount, setPickupCount] = useState(1);

    const closePopup = () => {
        setState("info");
        setPopupActive(false);
    };

    if (!popupActive) return null;

    const handlePickupCountChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (value > 0) {
            setPickupCount(value);
        } else {
            setPickupCount(1); // Ensure the value is always at least 1
        }
    };

    const handleCompletePickup = async () => {
        // if (token.subscription_type === "Pay-Per-Package"){
        //     await updateDoc(doc(db, "tokens", token.id), {
        //         arrived_date:  //adds elements to an array but only elements not already present
        //         picked_up: true
        //     });
        // }
        // await updateDoc(doc(db, "users", userID), {
        //     tokensID: arrayUnion(newTokenDoc.id), //adds elements to an array but only elements not already present
        // });
        // await updateDoc(doc(db, "partners", partner.business_id), {
        //     token_id_array: arrayUnion(userID), //adds elements to an array but only elements not already present
        // });
        alert(`Picked up ${pickupCount} packages.`);
        closePopup();
    };

    const PopupContent = ({ token, setLocationPopupActive }) => {
        switch (state) {
            case "info":
                return (
                    <div className="flex items-center h-full justify-center border rounded-md shadow-md bg-white border-gray-300">
                        <div className="flex flex-col h-full items-center justify-center space-y-7 px-10 w-30">
                            <div className="flex flex-col items-center w-full">
                                <p className="text-sm text-center italic text-gray-900">Ready to pick up?</p>
                                <button onClick={() => setState("pickup")} className="drop-shadow-md shadow-md text-sm bg-red-1 mt-1 hover:bg-red-400 text-white h-6 w-28 rounded-md transition duration-300">Use a token</button>
                            </div>
                            <div className="flex flex-col items-center w-full">
                                {token.subscription_type === 'Pay-Per-Package' && <p className="text-sm text-center text-gray-900 italic">{token.count} pickup token{token.count > 1 && "s"} left.</p>}
                                {token.subscription_type !== 'Pay-Per-Package' && <p className="text-sm text-center text-gray-900 italic">Unlimited tokens ({token.subscription_left} days)</p>}
                                <button onClick={() => setLocationPopupActive(true)} className="drop-shadow-md shadow-md text-sm bg-red-1 mt-1 hover:bg-red-400 text-white h-6 w-28 rounded-md transition duration-300">Buy More</button>
                            </div>
                        </div>
                        <div className="flex flex-col h-full justify-center text-center py-3 px-10 border-l">
                            <div className="flex justify-center items-center text-[12px] text-gray-600 text-center">
                                <div>
                                    <p className="mb-2 text-center font-medium text-gray-800 text-sm">Pickup Hours</p>
                                    <p>Mon: 8 A.M. - 7 P.M.</p>
                                    <p>Tues: 8 A.M. - 7 P.M.</p>
                                    <p>Wed: 8 A.M. - 7 P.M.</p>
                                    <p>Thu: 10 A.M. - 7 P.M.</p>
                                    <p>Fri: 8 A.M. - 7 P.M.</p>
                                    <p>Sat: 8 A.M. - 11 P.M.</p>
                                    <p>Sun: 8 A.M. - 11 P.M.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "pickup":
                return (
                    <div className="flex flex-col h-full w-96 items-center justify-center border bg-white py-3 px-16 rounded-lg">
                        <div className="flex justify-center items-center">
                            <p className="text-sm text-gray-700 w-32 mr-1 font-medium">How many packages are you picking up?</p>
                            <input 
                                name="pickupCount" 
                                type="number"
                                value={pickupCount}
                                min="1"
                                max="10"
                                onChange={handlePickupCountChange}
                                className="items-center flex text-gray-700 border text-xl text-center border-gray-300 rounded-md h-10 w-16 pl-3 focus:outline-none focus:border-red-500"/>
                        </div>
                        <button onClick={handleCompletePickup} className="text-base mt-7 bg-[#ff4343] hover:bg-red-400 hover:-translate-y-1 hover:shadow-lg hover:drop-shadow-lg text-white font-medium transition shadow-md drop-shadow-md duration-300 w-64 py-1 rounded-md">Use {pickupCount} token{pickupCount>1 ? "s" : ""} & complete pickup</button>
                    </div>
                );
            default:
                
                return null;
        }
    };

    return (
        <div>
            <div className="z-20 fixed fade-in bottom-0 w-screen h-screen bg-black opacity-60" />
            <div className="z-30 flex slide-in-bottom flex-col fixed bottom-0 inset-x-0 mx-auto justify-center items-center h-80 transition-all duration-200 w-[700px]">
                {/* Minimize Button */}
                <button onClick={() => closePopup()} className="flex w-24 h-6 bg-red-2 rounded-t-xl justify-center items-center p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="h-5 w-5 fill-white ml-1">
                        <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" />
                    </svg>
                </button>
                {/* Popup */}
                <div className="flex flex-col w-full h-full items-center pt-7 pb-5 bg-red-2 rounded-t-md">
                    <div className="flex flex-col items-center w-full">
                        <p className="font-bold text-white -mb-[2px]">{token.partner?.business_name}</p>
                        <p className="mb-4 text-sm italic text-red-200">Shipping Address: {token.partner?.street_name1} {token.partner?.street_name2}, {token.partner?.postal_code.toString().padStart(5, '0')}</p>
                    </div>
                    <PopupContent token={token} setLocationPopupActive={setLocationPopupActive} />
                </div>
            </div>
            <LocationInfoPopup partner={token.partner} popupActive={locationPopupActive} setPopupActive={setLocationPopupActive} userID={userID} />
        </div>
    );
});
