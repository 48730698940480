// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { Loader } from "@googlemaps/js-api-loader"
import { getFirestore, initializeFirestore, persistentLocalCache } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUOtSL04CNiDJpRie4AUp9RbWS77hRoO8",
  authDomain: "package-theft-3cc71.firebaseapp.com",
  projectId: "package-theft-3cc71",
  storageBucket: "package-theft-3cc71.appspot.com",
  messagingSenderId: "611114066859",
  appId: "1:611114066859:web:2a074748a780e53a4128ee",
  measurementId: "G-TYBQBMKKQN"
};

export const loader = new Loader({
    apiKey: "AIzaSyA7gKkZ-Wyw2t2fye8a67R3kTuHqU_nCXc",
    version: "weekly"
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {localCache: persistentLocalCache(/*settings*/{})});
export const auth = getAuth(app)