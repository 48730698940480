import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loader, db } from "../../firebase/config";
import { collection, onSnapshot, getDocsFromCache, getDocs, getDoc, doc, getDocFromCache, where, query } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const Info = () => {
    const [partner, setPartner] = useState({ id: null, packageIDs: null, userIDs: [] });
    const [partnerData, setPartnerData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [packagesList, setPackagesList] = useState([]);
    const navigate = useNavigate();
    const auth = getAuth();


    // get the current user and their tickets
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const userEmail = authUser.email;
                const querySnapshot = await getDocs(query(collection(db, 'partners'), where("business_owner_email", "==", userEmail)));
                const userDoc = querySnapshot.docs[0]
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setPartnerData(userData)
                    setPartner({id: userData.id, business_name: userData.business_name, email: userData.business_owner_email, tokenIDs: userData.token_id_array});
                    setLoading(false)
                } else {
                    navigate("/partnerLogin"); // Redirect to login if no user is found
                }
            } else {
                navigate("/partnerLogin"); // Redirect to login if no user is authenticated
            }
        });
        return () => unsubscribe();
    }, [navigate]);



    // // Get the current partner and their tickets/packages
    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, async (authPartner) => {
    //         if (authPartner) {
    //             const partnerDocRef = doc(db, "partners", "es2w3l9GjVj05YSP7nJI");
    //             const partnerDoc = await getDoc(partnerDocRef);
    //             if (partnerDoc.exists()) {
    //                 const partnerData = partnerDoc.data();
    //                 setPartner({ id: "es2w3l9GjVj05YSP7nJI", packageIDs: partnerData.packages, userIDs: partnerData.users });
    //             }
    //         }
    //     });
    //     return () => unsubscribe();
    // }, [auth]);

    // // Get all packages in the database that have been picked up at the partner today. Store in packagesList.
    // useEffect(() => {
    //     const fetchPackages = async (packageIDs) => {
    //         const packages = await Promise.all(packageIDs.map(async (packageID) => {
    //             const packageDocRef = doc(db, "packages", packageID);
    //             const packageDoc = await getDoc(packageDocRef);
    //             if (packageDoc.exists()) {
    //                 return packageDoc.data();
    //             }
    //             return null;
    //         }));
    //         return packages.filter(p => p !== null && p.pickup_date);
    //     };

    //     if (partner?.packageIDs?.length > 0) {
    //         fetchPackages(partner.packageIDs).then((packages) => setPackagesList(packages));
    //     }
    // }, [partner]);


    if(loading){
        return(<div>Loading..</div>)
    }

    return (
        <div className="flex justify-center mt-28 space-x-20 items-start">
            <div className="flex flex-col w-[500px] h-full">
                {/* <div className="mt-2 pb-4">
                    <p className="text-lg text-center font-medium">Info & Settings</p>
                    <p className="text-sm text-center pb-1 text-gray-500 mb-2">Below is your business information and other details.</p>
                </div> */}
                <div className="w-full text-sm text-gray-400 rounded-md border border-gray-200 py-6 overflow-y-auto flex flex-col space-y-3 px-16 items-start shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
                    <div>
                        <p>Business Name:</p> 
                        <p className="text-base text-gray-900">{partnerData.business_name}</p>
                    </div>
                    <div>
                        <p>Account Email:</p> 
                        <p className="text-base text-gray-900">{partnerData.business_owner_email}</p>
                    </div>
                    <div>
                        <p>Shipping Address: </p>
                        <div className="pl-5">
                            <p className="text-base text-gray-900">{partnerData.street_name1}</p>
                            <p className="text-base text-gray-900">{partnerData.street_name2}</p>
                            <p className="text-base text-gray-900">{partnerData.postal_code}</p>
                        </div>
                    </div>
                    <div>
                        <p>Delivery/Pickup Hours: </p>
                        <div className="pl-5 text-base text-gray-900">
                            {partnerData.business_hours.map((day) => {
                                const openHour = day.openTime.hour.toString().padStart(2, '0')
                                const openMinute = day.openTime.minute.toString().padStart(2, '0')
                                const closeHour = day.closeTime.hour.toString().padStart(2, '0')
                                const closeMinute = day.closeTime.minute.toString().padStart(2, '0')
                                const open = (day.open === true ? "Open" : "Closed")
                                return (
                                    <p>{day.day} {openHour}:{openMinute} - {closeHour}:{closeMinute} {open}</p>
                                )
                            })}
                        </div>
                    </div>
                    
                    {/* {packagesList.map((p, index) => (
                        <div key={index} className={"w-full py-2 px-5 rounded-lg bg-white border border-gray-300 text-black transition duration-300 drop-shadow-lg"}>
                            <p className="text-sm font-semibold">Package for {p.logged_name?.first} {p.logged_name?.last}</p>
                            <p className="text-sm italic text-gray-500">Delivery logged on {p.delivery_date.toDate().toDateString()}</p>
                        </div>
                    ))} */}
                </div>
            </div>
        </div>
    );
};
