import { useEffect, useState, memo } from "react";
import { collection, addDoc, doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore"; 
import { db } from "../../firebase/config";
import { Timestamp } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const auth = getAuth();


export const PartnerSettingsPopup = memo(({ popupActive, setPopupActive}) => {

    const [user, setUser] = useState({})
    const navigate = useNavigate()

    // get the current user and their firebase document
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                let userDoc = await getDoc(doc(db, "users", authUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUser(userData);
                } else {
                    alert("Error fetching user data. Try reloading the page.")
                }
            } else {
                navigate("/login"); // Redirect to login if no user is authenticated
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleLogout = async () => {
        await signOut(auth);
        navigate("/login");
    };

    if (!popupActive) return null;

    return (
        <div>
            <div className="z-40 fixed fade-in bottom-0 w-screen h-screen bg-black opacity-30" />
            <div className="z-50 fixed slide-in-right flex bottom-0 right-0 justify-center items-center h-screen w-[380px]">
                {/* Minimize Button */}
                <button onClick={() => setPopupActive(false)} className="flex w-7 h-24 bg-white rounded-l-md justify-center items-center p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="h-6 w-6 -rotate-90 fill-black ml-1 ">
                        <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" />
                    </svg>
                </button>
                {/* Popup */}
                <div className="overflow-auto flex flex-col w-full h-full justify-top items-center py-5 bg-white px-10 justify-between">
                    <div className="w-full h-auto">
                        {/* Name */}
                        <div className="flex justify-center space-x-2 mt-8 mb-3 mr-3">
                            <svg className="h-7 w-7 items-center justify-center fill-gray-900" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
                                <g><circle cx="48" cy="30" r="16"/><path d=" M 80 82 L 80 66 C 80 63.6 78.8 61.2 76.8 59.6 C 72.4 56 66.8 53.6 61.2 52 C 57.2 50.8 52.8 50 48 50 C 43.6 50 39.2 50.8 34.8 52 C 29.2 53.6 23.6 56.4 19.2 59.6 C 17.2 61.2 16 63.6 16 66 L 16 82 L 80 82 Z"/></g>
                            </svg>
                            <p className="text-gray-900 text-lg font-medium items-start mt-[2px]">{user.name.first} {user.name.last}</p>
                        </div>
                        {/* Info */}
                        <div className="flex flex-col space-y-1 mt-7 items-center shadow-md rounded-md bg-white border border-neutral-300 w-full py-3">
                            <p className="text-gray-700 text-sm">{user.email}</p>
                            <button className="text-gray-700 text-sm italic hover:text-gray-400 transition-all duration-200">Change password</button>
                        </div>
                    </div>
                    {/* Logout */}
                    <button onClick={handleLogout} className="flex justify-center text-sm items-center text-gray-500 border bg-gray-50 rounded-md h-6 w-24 transition duration-300 mt-12 mb-2 hover:text-black hover:bg-gray-100">
                        <p>Log Out</p>
                    </button>
                </div>
            </div>
        </div>
    );
});
