import { useState, useEffect } from "react";
import { auth, db } from "../../firebase/config";
import { getDoc, doc, query, where, collection, getDocs } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

const provider = new GoogleAuthProvider();

export const PartnerLogin = () => {
    const [userCredentials, setUserCredentials] = useState({});
    const navigate = useNavigate();


    async function googleSignIn() {
        try {
            const result = await signInWithPopup(auth, provider);
            const userEmail = result.user.email;
            const querySnapshot = await getDocs(query(collection(db, 'partners'), where("business_owner_email", "==", userEmail)));
            const userDoc = querySnapshot.docs[0] 
            if (userDoc.exists()) { // only if there is a partner doc whose business email is the same as this login email
                navigate("/pickups");
            } else {
                alert("Invalid login.");
                await auth.signOut(); // Explicitly sign out the user
            }
        } catch (error) {
            alert("Invalid login credentials.", error);
        }
    }

    function handleCredentials(e) {
        setUserCredentials({...userCredentials, [e.target.name]: e.target.value});
        console.log(userCredentials);
    }

    async function handleLogin(e) {
        e.preventDefault();
        try {
            const credential = await signInWithEmailAndPassword(auth, userCredentials.email, userCredentials.pass)
            const userDoc = await db.collection('partners').where(`business_owner_email`, '==', credential.user.email).get();
            if (userDoc.exists()){
                navigate("/pickups");
            } else {
                alert("Invalid login.")
            }
        } catch (error) {
            alert("Invalid login.", error);
        }
    }

    return (
        <div className="relative flex flex-row w-full min-h-screen">
            {/* Background */}
            {/* <img className="absolute object-cover w-full h-full opacity-60 mix-blend-multiply object" 
                src="./hero-image-map.png"
                style={{ objectPosition: 'center 30%' }}>
            </img> */}
            <div className="absolute w-full h-full bg-gray-50"/>

            {/* Info Box */}
            <div className="absolute flex flex-col w-full h-full items-center justify-center drop-shadow-md">
                {/* Title */}
                <div className="flex flex-col justify-center items-center bg-red-1 w-[450px] h-[90px] rounded-t-lg">
                    <p className="text-white text-2xl font-light"><a className="font-bold">PickPackGo</a> Partner Login</p>
                    <p className="text-white text-opacity-75 text-sm font-normal">Not a partner yet? Fill out an <a className="underline hover:text-white" href="https://forms.gle/GoMPHiTmKq2mRK4eA">interest form</a>!</p>
                </div>
                {/* Content */}
                <div className="flex justify-center justify-items-center items-center bg-white w-[450px] h-[380px] rounded-b-lg">
                    <div className="flex flex-col w-2/3 mb-10">
                        <button onClick={() => googleSignIn()} className="shadow-md flex justify-center items-center space-x-2 text-white rounded-full h-10 w-full bg-red-1 hover:bg-red-400 transition duration-300">
                            <img className="h-[25px] bg-white rounded-full p-1" src="./google-icon.png"/>
                            <p>Log in using Google</p>
                        </button>
                        <div className="flex items-center space-x-2 my-7">
                            <hr className="grow h-[1px] bg-gray-3"/>
                            <p className="text-sm grow-0">OR</p>
                            <hr className="grow h-[1px] bg-gray-3"/>
                        </div>
                        <input 
                            onChange={(e) => {handleCredentials(e)}} 
                            name="email" 
                            placeholder="Email" 
                            className="items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-7 w-full pl-3 mb-2 focus:outline-none focus:border-red-500 shadow-md"/>
                        <input 
                            onChange={(e) => {handleCredentials(e)}} 
                            name="pass" 
                            type="password"
                            placeholder="Password" 
                            className="items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-7 w-full pl-3 mb-4 focus:outline-none focus:border-red-500 shadow-md"/>
                        <button onClick={(e) => {handleLogin(e)}} className="flex justify-center items-center bg-red-1 text-white rounded-full h-10 w-full hover:bg-red-400 transition duration-300 shadow-md">
                            <p>Log In</p>
                        </button>
                    </div>
                </div>
            </div>

            <button onClick={()=>navigate("/")} className="group absolute flex px-4 pt-1 justify-center">
                <svg className="fill-red-1 w-10 stroke-2 transition-transform duration-300 group-hover:-translate-x-2 items-center justify-center rotate-180" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 476.213 476.213">
                    <polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106"/>
                </svg>
            </button>

        </div>
    )
}
