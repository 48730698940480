import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth"; // Import Firebase authentication
import { SettingsPopup } from "./userSettingsPopup";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebase/config";

let PAGES = {
    BUY: "Buy",
    TRACK: "Track"
}

export const UserHeader = ({ currentPage }) => {

    const [page, setPage] = useState(currentPage);
    const [popupActive, setPopupActive] = useState(false);
    const navigate = useNavigate();

    const togglePage = () => {
        if (page === PAGES.BUY) {
            setPage(PAGES.TRACK);
            navigate("/track");
        } else {
            setPage(PAGES.BUY);
            navigate("/buyTickets");
        }
    }

    const handleLogout = async () => {
        try {
            await signOut(auth).then(() => navigate("/login")); // Navigate to the login page after logout
        } catch (error) {
            console.error("Error logging out:", error);
        }
    }

    return (
        <>  
            <div className="z-10 fixed flex top-0 w-full h-10 items-center bg-red-2 px-1 justify-between">
                <div className="flex ml-2">
                    <button onClick={() => navigate("/")} className="text-white font-medium text-[21px] pl-3">PickPackGo</button>
                </div>
                <div className="flex items-center">
                    {/* Switch */}
                    <div className="relative w-48 h-[27px] bg-white rounded-full p-[2px] text-base">
                        <div className={`absolute top-[2px] h-[23px] w-[49%] bg-red-2 rounded-full transition-transform duration-300 ${page === PAGES.BUY ? 'translate-x-0' : 'translate-x-full'}`}></div>
                        <button onClick={() => togglePage()} className={`absolute top-0 left-0 w-1/2 h-full text-center font-semibold transition-colors duration-300 ${page === PAGES.BUY ? 'text-white' : 'text-black'}`}>
                            Buy
                        </button>
                        <button onClick={() => togglePage()} className={`absolute top-0 right-0 w-1/2 h-full text-center font-semibold transition-colors duration-300 ${page === PAGES.TRACK ? 'text-white' : 'text-black'}`}>
                            Track
                        </button>
                    </div>
                    {/* Profile Button */}
                    <button onClick={()=>setPopupActive(true)} className="ml-3 flex items-center justify-center mr-2">
                        <svg className="fill-white stroke-white h-7 w-7" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="m4.5 6.5h12"/><path d="m4.498 10.5h11.997"/><path d="m4.5 14.5h11.995"/></g>
                        </svg>
                    </button>
                </div>
            </div>

            <SettingsPopup popupActive={popupActive} setPopupActive={setPopupActive} />

            <Outlet/>
        </>
    )
}
