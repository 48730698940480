import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { loader, db } from "../../firebase/config";
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";
import { GetDistances } from "../../components/getDistances";
import { LocationInfoPopup } from "../../components/locationInfoPopup";
import { useNavigate } from "react-router-dom";

const auth = getAuth();

export const BuyTickets = () => {
    const [user, setUser] = useState({id: null, email: null, tokenIDs: null, location: []});
    const [partnersList, setPartnersList] = useState([]);
    const [filteredPartners, setFilteredPartners] = useState([]);
    const [popupActive, setPopupActive] = useState(false);
    const [partnerSelected, setPartnerSelected] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    // get the current user and their tickets
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                let userDoc = await getDoc(doc(db, "users", authUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setUser({id: authUser.uid, email: userData.email, tokenIDs: userData.tokenIDs, location: userData.location});
                } else {
                    navigate("/login"); // Redirect to login if no user is found
                }
            } else {
                navigate("/login"); // Redirect to login if no user is authenticated
            }
        });
        return () => unsubscribe();
    }, [navigate]);


    // get all partners in the database. store in partnersList
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "partners"), (snapshot) => {
            const data = [];
            snapshot.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            setPartnersList(data);
            setFilteredPartners(data); // Initialize the filtered list with all partners
        });

        return () => unsubscribe(); 
        // Cleanup the listener on component unmount
    }, []);


    // Update the filtered list based on the search query
    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredPartners(partnersList);
        } else {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const filtered = partnersList.filter(partner => {
                const name = partner?.business_name?.toLowerCase() || "";
                const address = (partner?.street_name1?.toLowerCase()).concat(" ", partner?.street_name2?.toLowerCase()) || "";
                return name.includes(lowerCaseQuery) || address.includes(lowerCaseQuery);
            });
            setFilteredPartners(filtered);
        }
    }, [searchQuery, partnersList]);


    // load the map
    useEffect(() => {
        if (filteredPartners.length > 0) {
            const mapOptions = { center: { lat: 41.310307, lng: -72.926985 }, zoom: 16, mapId: "4504f8b37365c3d0", disableDefaultUI: true };

            loader.load().then(async (google) => {
                
                //import google api libraries
                const { Map } = await google.maps.importLibrary("maps");
                const { Geocoder } = await google.maps.importLibrary("geocoding")
                const map = new Map(document.getElementById("map"), mapOptions);
                const infoWindow = new google.maps.InfoWindow();
                const geocoder = new Geocoder()

                if (map && filteredPartners.length > 0) {
                    // for each partner in filteredPartners, geocode its address (to get coords) and draw a marker + create infowindow listener
                    filteredPartners.forEach((partner) => {
                        geocoder.geocode( {address: (partner?.street_name1).concat(" ", partner?.street_name2)}, (results, status) =>{
                            // if the geocode is successful
                            if (status == 'OK') {
                                // create a marker on the map
                                const marker = new google.maps.Marker({
                                    map,
                                    position: results[0].geometry.location,
                                    title: partner.business_name,
                                    icon: {
                                        url: "/pin.png", 
                                        scaledSize: new google.maps.Size(42, 63),
                                        origin: new google.maps.Point(0, 0),
                                        anchor: new google.maps.Point(16, 32)
                                    }
                                });
                                // add a listener for the infowindow to appear when the marker is clicked
                                marker.addListener("click", () => {
                                    infoWindow.setHeaderContent(partner.business_name);
                                    infoWindow.open(map, marker);
                                });
                            //if the geocode is NOT successful
                            } else {
                                console.log('error with an address geocode')
                            }
                        })
                    });
                }
            });
        }
        window.scrollTo(0, 0);
    }, [filteredPartners]);


    // if this function is called, open the info sidebar popup for the selected business
    const handleInfoPopup = (businessID) => {
        const fetchData = async () => {
            const querySnapshot = await getDoc(doc(db, "partners", businessID));
            const data = querySnapshot.data();
            setPartnerSelected(data);
        };
        fetchData().then(() => setPopupActive(true));
    };

    return (
        <div>
            <div>
                <div className="flex justify-center items-center space-x-10 mt-36 mb-28 border-y bg-white border-gray-400 py-3 mx-32 rounded-md ">
                    <img className="w-24 slide-in-left" src="./logo.png" />
                    <div className="flex flex-col font-light text-[17px] text-gray-900">
                        <p className="text-left font-light text-base text-gray-600 -my-[1px]"><a className= "text-lg font-semibold text-black">Pick</a> a location to buy a Pickup Token for.</p>
                        <p className="text-left font-light text-base text-gray-600 -my-[1px]"><a className= "text-lg font-semibold text-black">Pack</a> and ship to the address you are given.</p>
                        <p className="text-left font-light text-base text-gray-600 -my-[1px]"><a className= "text-lg font-semibold text-black">Go</a> get your package from there once it arrives!</p>
                    </div>
                </div>
                {/* <div className="flex items-center mb-1 space-x-3 pl-7">
                    <p className="text-lg text-black mb-2">Recommended For You</p>
                </div>
                <div className="flex flex-col bg-white pt-7 pb-7 border">
                    <div className="flex space-x-3 pl-7 w-full">
                        {topFive.map((info) => (
                            <div key={info.business.id} className=" shadow-[0_2px_4px_rgba(0,0,0,0.1)] flex flex-col h-24 w-64 rounded-lg items-center border border-gray-300 justify-center p-2 bg-white text-black transition duration-300">
                                <p className="text-sm font-semibold">{info.business.name}</p>
                                <p className="text-xs">{(info.distance).toFixed(2)} km from you -- ${info.business.ratePerDay} per ticket</p>
                                <button onClick={() => handleInfoPopup(info.business.id)} className="bg-red-1 hover:bg-red-3 w-24 h-[18px] rounded-full text-xs text-white mt-3">Select</button>
                            </div>
                        ))}
                    </div>
                </div> */}
                <div className="flex h-[450px] justify-center space-x-12 px-32 mb-20">
                    <div id="map" className="w-[53%] h-full rounded-md shadow-md border">
                        {/* Map is rendered in this empty div! */}
                    </div>
                    <div className="flex grow flex-col">
                        <div className="flex items-center bg-red-1 text-white px-7 py-2 rounded-t-md">
                            <p className="text-base font-medium w-auto mr-4">Search Locations</p>
                            <input onChange={(e) => setSearchQuery(e.target.value)} name="search" placeholder="Enter a location" className="items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-6 grow pl-3 focus:outline-none focus:border-red-2" />
                        </div>
                        <div className="flex flex-col space-y-4 overflow-y-auto w-full py-9 px-8 drop-shadow-md shadow-md rounded-b-md justify-start items-start border border-gray-300">
                            {filteredPartners.map((info) => (
                                <button onClick={() => handleInfoPopup(info.id)} key={info.id} className="flex flex-col py-3 w-full border rounded-lg border-gray-300 items-center justify-center p-2 bg-white focus:-translate-y-1 focus:shadow-lg hover:-translate-y-1 hover:shadow-lg shadow-[0_2px_4px_rgba(0,0,0,0.1)] text-black transition duration-300">
                                    <p className="text-sm font-semibold">{info?.business_name ? info?.business_name : "Name"}</p>
                                    <p className="text-xs">Starting at $1.75 per ticket</p>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <LocationInfoPopup partner={partnerSelected} popupActive={popupActive} setPopupActive={setPopupActive} userID={user.id} />
        </div>
    );
};
