import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase/config";
import { doc, getDoc, collection, getDocs, query, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const today = new Date();
const auth = getAuth();

export const Pickups = () => {
    const [loading, setLoading] = useState(true)
    const [partner, setPartner] = useState({ id: null, email: null, tokenIDs: null });
    const [usersList, setUsersList] = useState([]);
    const [usersSearchResult, setUsersSearchResult] = useState([]);
    const [usedTokensList, setUsedTokensList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const [activeButtonId, setActiveButtonId] = useState(null); // Track the ID of the active button
    const [buttonState, setButtonState] = useState("idle"); // State of the active button: idle, confirm, confirmed
    const [confirmInputValue, setConfirmInputValue] = useState("");
    const buttonContainerRef = useRef(null); // Ref to the button container

    const navigate = useNavigate();
    const inputRef = useRef(null); // Ref to the input element

    
    // get the current user and their tickets
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const userEmail = authUser.email;
                const querySnapshot = await getDocs(query(collection(db, 'partners'), where("business_owner_email", "==", userEmail)));
                const userDoc = querySnapshot.docs[0]
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setPartner({id: userData.id, business_name: userData.business_name, email: userData.business_owner_email, tokenIDs: userData.token_id_array});
                    setLoading(false)
                } else {
                    navigate("/partnerLogin"); // Redirect to login if no user is found
                }
            } else {
                navigate("/partnerLogin"); // Redirect to login if no user is authenticated
            }
        });
        return () => unsubscribe();
    }, [navigate]);


    // Get all tokens in the partner's tokenID list that have been used TODAY. Store in usedTokensList.
    useEffect(() => {
        const fetchPackages = async (tokenIDs) => {
            const tokens = await Promise.all(tokenIDs.map(async (tokenID) => {
                const tokenDocRef = doc(db, "tokens", tokenID);
                const tokenDoc = await getDoc(tokenDocRef);
                if (tokenDoc.exists()) {
                    return tokenDoc.data();
                }
                return null;
            }));
            return tokens.filter(token => token !== null )
                // && token.pickup_date?.toDate().toDateString() === today.toDateString());
        };

        if (partner?.tokenIDs?.length > 0) {
            fetchPackages(partner.tokenIDs).then((tokens) => setUsedTokensList(tokens));
        }
    }, [partner]);

    if(loading){
        return(<div>Loading..</div>)
    }

    return (
        <div className="flex justify-center mt-28 space-x-12 items-start">

            <div className="flex flex-col w-full justify-center items-center mx-32">
                {/* Title */}
                <div className="flex justify-center items-center mb-14 border-y w-full py-3 border-gray-400">
                    <img className="w-24 mr-10 slide-in-left" src="./logo.png"></img>
                    <div className="flex flex-col">
                        <p className="font-semibold text-2xl mb-1">{partner?.business_name}</p>
                        <p className="text-sm font-light text-gray-500">Make sure that each user uses a pickup ticket.</p>  
                        <p className="text-sm font-light text-gray-500">Check this by making sure that their name appears below!</p>  
                    </div>
                </div>
                {/* Box Title */}
                <div className="mt-3 py-1 bg-red-1 w-[550px] text-white rounded-t-md">
                    <p className="text-lg text-center font-medium">Recent Pickups</p>
                </div>
                {/* Box Content */}
                <div className="w-[550px] h-[350px] rounded-b-md border border-gray-300 py-4 overflow-y-auto flex flex-col space-y-3 px-5 mb-20 items-center shadow-[0_2px_4px_rgba(0,0,0,0.1)]">
                    {usedTokensList.map((token) => (
                        <div key={token.id} className="w-full flex justify-between items-center py-2 px-8 border border-gray-300 rounded-md bg-white shadow-sm">
                            <p className="text-sm font-semibold">{token.pick_up_first_name} {token.pick_up_last_name}</p>
                            <p className="text-sm text-gray-500">{token.picked_up_date?.toDate().toLocaleString('en-US', {year: 'numeric',
                                                                                                                            month: 'long',
                                                                                                                            day: 'numeric',
                                                                                                                            hour: '2-digit',
                                                                                                                            minute: '2-digit',
                                                                                                                            second: '2-digit',
                                                                                                                        })}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
