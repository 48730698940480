import { useReducer, useState, useEffect } from "react";
import { auth, db } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { setDoc, doc, getDoc } from "firebase/firestore";

const provider = new GoogleAuthProvider();

const STATES = {
    WAITING_FOR_TYPE: "waitingForSignupType",
    GOOGLE: "googleSelected",
    EMAIL: "emailSelected",
    NAME: "enteringName",
}

// TODO: add section for user to input their name to use for package tracking

const SignupContent = ({ currentState, setCurrentState, handleCredentials, handleSignup, googleSignIn, createFirebaseUser }) => {
    switch (currentState) {
        case STATES.WAITING_FOR_TYPE:
            return (
                <div className="flex justify-center justify-items-center items-center bg-white w-[450px] h-[380px] rounded-b-lg">
                    <div className="flex flex-col space-y-9 w-2/3">
                        <button onClick={() => googleSignIn()} className="shadow-md flex justify-center items-center space-x-2 text-white rounded-full h-10 w-full bg-red-1 hover:bg-red-400 transition duration-300">
                            <img className="h-[25px] bg-white rounded-full p-1" src="./google-icon.png"/>
                            <p>Use Google</p>
                        </button>
                        <div className="flex items-center space-x-2">
                            <hr className="grow h-[1px] bg-gray-3"/>
                            <p className="text-sm grow-0">OR</p>
                            <hr className="grow h-[1px] bg-gray-3"/>
                        </div>
                        <button onClick={() => setCurrentState(STATES.EMAIL)} className="shadow-md flex justify-center items-center text-white rounded-full h-10 w-full bg-red-1 hover:bg-red-400 transition duration-300">
                            <p>Use email</p>
                        </button>
                    </div>
                </div>
            );
        
        case STATES.EMAIL:
            return (
                <div className="flex justify-center justify-items-center items-center bg-white w-[450px] h-[380px] rounded-b-lg">
                    <div className="flex flex-col w-2/3 mb-10">
                        <p className="w-full text-center text-sm mb-4">Enter your email and password.</p>
                        <input 
                            onChange={(e) => {handleCredentials(e)}} 
                            name="email" 
                            pattern="[A-Za-z ]+"
                            type="text"  
                            placeholder="Email" 
                            className="shadow-md items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-7 w-full pl-3 mb-6 focus:outline-none focus:border-red-500"/>
                        <input 
                            onChange={(e) => {handleCredentials(e)}} 
                            name="pass" 
                            type="password"
                            placeholder="Password" 
                            className="shadow-md items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-7 w-full pl-3 mb-2 focus:outline-none focus:border-red-500"/>
                        <input 
                            onChange={(e) => {handleCredentials(e)}} 
                            name="passVerify" 
                            type="password"
                            placeholder="Verify password" 
                            className="shadow-md items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-7 w-full pl-3 mb-6 focus:outline-none focus:border-red-500"/>
                        <button onClick={(e) => {handleSignup(e)}} className="shadow-md flex justify-center items-center bg-red-1 text-white rounded-full h-10 w-full hover:bg-red-400 transition duration-300">
                            <p>Continue</p>
                        </button>
                    </div>
                </div>
            );

        case STATES.NAME:
            return (
                <div className="flex justify-center justify-items-center items-center bg-white w-[450px] h-[380px] rounded-b-lg">
                    <div className="flex flex-col w-2/3 mb-10">
                        <p className="w-full text-center text-sm mb-4">Enter your name as it appears on your ID.</p>
                        <input 
                            onChange={(e) => {handleCredentials(e)}} 
                            name="firstName" 
                            placeholder="First name" 
                            key="first"
                            className="shadow-md items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-7 w-full pl-3 mb-2 focus:outline-none focus:border-red-500"/>
                        <input 
                            onChange={(e) => {handleCredentials(e)}} 
                            name="lastName" 
                            placeholder="Last name" 
                            key="last"
                            className="shadow-md items-center flex text-gray-500 border text-sm border-gray-300 rounded-md h-7 w-full pl-3 mb-6 focus:outline-none focus:border-red-500"/>
                        <p className=" w-full text-center text-sm mb-6">Make sure this is correct! For package security, Businesses check ID at pickup.</p>
                        <button onClick={() => createFirebaseUser()} className="shadow-md flex justify-center items-center bg-red-1 text-white rounded-full h-10 w-full hover:bg-red-400 transition duration-300">
                            <p>Create Account</p>
                        </button>
                    </div>
                </div>
            );

        default:
            return null;
    }
};


export const UserSignup = () => {
    const [currentState, setCurrentState] = useState(STATES.WAITING_FOR_TYPE);
    const [userCredentials, setUserCredentials] = useState({});
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    async function createFirebaseUser() {
        if (user) {
            await setDoc(doc(db, "users", user.uid), {
                email: user.email,
                tokensID: [],
                first_name: userCredentials.firstName,
                last_name: userCredentials.lastName,
            }).then(() => navigate("/buyTickets"));
        }
    }

    async function googleSignIn() {
        let newUser = {}
        await signInWithPopup(auth, provider)
            .then(async(result) => {
                newUser = result.user;
            }).catch((error) => {
                alert("Google sign-in error. Try reloading the page!", error);
            });
        await getDoc(doc(db, "users", newUser.uid))
            .then((userDoc) => {
                console.log(newUser.uid)
                if (userDoc.exists()){
                    alert("There's already an account with that email. Please log in or reset your password.")
                } else {
                    setUser(newUser);
                    setCurrentState(STATES.NAME);
                }
            })
    }


    function handleSignup(e) {
        e.preventDefault();
        if (userCredentials.pass !== userCredentials.passVerify){
            alert('Passwords do not match.');
            return; //so the switch below does not acivate aswell
        }
        createUserWithEmailAndPassword(auth, userCredentials.email, userCredentials.pass)
            .then((userCredential) => {
                setUser(userCredential.user);
                setCurrentState(STATES.NAME);
            })
            .catch((error) => {
                console.error("Email signup error:", error);
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        alert('The email address is already in use by another account.');
                        break;
                    case 'auth/invalid-email':
                        alert('The email address is not valid.');
                        break;
                    case 'auth/missing-password':
                        alert('Please enter a password.');
                        break;
                    case 'auth/weak-password':
                        alert('The password is too weak. Password should be at least 6 characters');
                        break;
                    case 'auth/internal-error':
                        alert('An internal error occurred. Please try again later.');
                        break;
                    default:
                        alert('An unknown error occurred. Please try again!');
                        break;
                }
            });
    }


    function handleCredentials(e) {
        setUserCredentials({...userCredentials, [e.target.name]: e.target.value});
    }


    return (
        <div className="relative flex flex-row w-full min-h-screen ">
            {/* Background */}
            {/* <img className="absolute object-cover w-full h-full opacity-60 mix-blend-multiply object" 
                src="./hero-image-map.png"
                style={{ objectPosition: 'center 30%' }}>
            </img> */}
            <div className="absolute w-full h-full bg-gray-100"/>

            {/* Info Box */}
            <div className="absolute flex flex-col w-full h-full items-center justify-center drop-shadow-md">
                {/* Title */}
                <div className="flex flex-col justify-center items-center bg-red-1 w-[450px] h-[90px] rounded-t-lg">
                    <p className="text-white text-2xl font-light">Create a <a className="font-bold">PickPackGo</a> Account</p>
                    <p className="text-white text-opacity-75 text-sm font-normal">Already have an account? <button className="underline hover:text-white" onClick={()=>navigate("/login")}>Log in</button></p>
                </div>
                {/* Content */}
                <SignupContent
                    currentState={currentState}
                    setCurrentState={setCurrentState}
                    handleCredentials={handleCredentials}
                    handleSignup={handleSignup}
                    googleSignIn={googleSignIn}
                    createFirebaseUser={createFirebaseUser}
                />
            </div>

            <button onClick={()=>navigate("/")} className="group absolute flex px-4 pt-1 justify-center">
                <svg className="drop-shadow-md fill-red-1 w-10 transition-transform duration-300 group-hover:-translate-x-2 items-center justify-center rotate-180" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 476.213 476.213">
                    <polygon points="345.606,107.5 324.394,128.713 418.787,223.107 0,223.107 0,253.107 418.787,253.107 324.394,347.5 345.606,368.713 476.213,238.106"/>
                </svg>
            </button>

        </div>
    );
};
