import { useEffect, useState, memo } from "react";
import { collection, addDoc, doc, updateDoc, arrayUnion } from "firebase/firestore"; 
import { db } from "../firebase/config";
import { Timestamp } from "firebase/firestore";


export const LocationInfoPopup = memo(({ partner, popupActive, setPopupActive, userID }) => {

    const [selectedPlan, setSelectedPlan] = useState(2);
    const [tokenCount, setTokenCount]  = useState(0)

    const handlePlanSelection = (selection) => {
        setSelectedPlan(selection);
    };

    const handlePurchase = async () => {
        let plan_name = ""

        if (selectedPlan === 1 ) plan_name = "Pay-Per-Package"
        if (selectedPlan === 2 ) plan_name = "1 Month Subscription"
        if (selectedPlan === 3 ) plan_name = "3 Month Subscription"

        const newTokenDoc = await addDoc(collection(db, "tokens"), {
            active_subscription: (plan_name === "Pay-Per-Package" ? false : true),
            late_days: 0,
            late_fees: 0,
            num_of_tokens: tokenCount,
            picked_up: false,
            partner_id: partner.business_id,
            token_purchase_date: Timestamp.fromDate(new Date()),
            subscription_type: plan_name,
            picked_up_date: null,
            user_id: userID
          });
        await updateDoc(doc(db, "users", userID), {
            tokensID: arrayUnion(newTokenDoc.id), //adds elements to an array but only elements not already present
        });
        await updateDoc(doc(db, "partners", partner.business_id), {
            token_id_array: arrayUnion(userID), //adds elements to an array but only elements not already present
        });
          
        console.log("user has bought" + plan_name + "for" + partner.business_name);
    }

    const handleTokenCountChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (value > 0) {
            setTokenCount(value);
        } else {
            setTokenCount(1); // Ensure the value is always at least 1
        }
    };


    const PlansContent = ({partner, selectedPlan, handlePlanSelection}) => {

        switch (selectedPlan) {
    
            case 1:
                return (
                    <div className="shadow-md flex flex-col rounded-md w-4/5 justify-center items-center pt-5 px-14 bg-white border border-neutral-400">
                        <div className="mb-3">
                            <div className="flex justify-center items-center mb-1">
                                <input 
                                    name="pickupCount" 
                                    type="number"
                                    value={tokenCount}
                                    min="1"
                                    max="10"
                                    onChange={handleTokenCountChange}
                                    className="items-center flex text-black border text-center border-gray-300 rounded-md h-6 w-9 pl-3 focus:outline-none  focus:border-red-500"/>
                                <p className="font-bold text-black text-center ml-2">Token{tokenCount > 1 ? "s" : ""}</p>
                            </div>
                            <p className="text-black text-sm text-center h-14">
                                Buy individual tokens for {partner?.business_name}.
                            </p>
                        </div>
                        <div className="grid grid-cols-3 w-full gap-3 mb-7">
                            <button onClick={() => handlePlanSelection(1)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 1 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$2.50</p>
                                <p className="text-sm">per token</p>
                            </button>
                            <button onClick={() => handlePlanSelection(2)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 2 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$2.15*</p>
                                <p className="text-sm">per token</p>
                            </button>
                            <button onClick={() => handlePlanSelection(3)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 3 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$1.75*</p>
                                <p className="text-sm">per token</p>
                            </button>
                        </div>
                        <button onClick={()=>handlePurchase()} className="w-28 text-center text-white text-sm bg-red-1 mb-5 rounded-md py-1 shadow-md hover:bg-red-500 hover:-translate-y-1 hover:shadow-lg transition duration-300">Purchase</button>
                        <p className="text-xs text-neutral-400 text-center mb-1">*If you were to use 3 pickup tokens a month</p>
                    </div>
                );
            
            case 2:
                return (
                    <div className="shadow-md flex flex-col rounded-md w-4/5 justify-center items-center pt-5 px-14 bg-white border border-neutral-400">
                        <div className="mb-3">
                            <p className="font-bold text-black mb-1 text-center">1-Month Subscription</p>
                            <p className="text-black text-sm text-center h-14">
                                Buy a 1-month unlimited-token subscription for {partner?.business_name}.
                            </p>
                        </div>
                        <div className="grid grid-cols-3 w-full gap-3 mb-7">
                            <button onClick={() => handlePlanSelection(1)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 1 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$2.50</p>
                                <p className="text-sm">per token</p>
                            </button>
                            <button onClick={() => handlePlanSelection(2)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 2 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$2.15*</p>
                                <p className="text-sm">per token</p>
                            </button>
                            <button onClick={() => handlePlanSelection(3)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 3 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$1.75*</p>
                                <p className="text-sm">per token</p>
                            </button>
                        </div>
                        <button onClick={()=>handlePurchase()} className="w-28 text-center text-white text-sm bg-red-1 mb-5 rounded-md py-1 shadow-md hover:bg-red-500 hover:-translate-y-1 hover:shadow-lg transition duration-300">Purchase</button>
                        <p className="text-xs text-neutral-400 text-center mb-1">*If you were to use 3 pickup tokens a month</p>
                    </div>
                );

            case 3:
                return (
                    <div className="shadow-md flex flex-col rounded-md w-4/5 justify-center items-center pt-5 px-14 bg-white border border-neutral-400">
                        <div className="mb-3">
                            <p className="font-bold text-black mb-1 text-center">3-Month Subscription</p>
                            <p className="text-black text-sm text-center h-14">
                                Buy a 3-month unlimited-token subscription for {partner?.business_name}.
                            </p>
                        </div>
                        <div className="grid grid-cols-3 w-full gap-3 mb-7">
                            <button onClick={() => handlePlanSelection(1)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 1 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$2.50</p>
                                <p className="text-sm">per token</p>
                            </button>
                            <button onClick={() => handlePlanSelection(2)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 2 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$2.15*</p>
                                <p className="text-sm">per token</p>
                            </button>
                            <button onClick={() => handlePlanSelection(3)} className={`shadow-md flex flex-col h-28 items-center justify-center rounded-lg ${selectedPlan === 3 ? 'bg-red-1 text-white' : 'border border-neutral-400 text-black'}`}>
                                <p className="font-medium text-xl">$1.75*</p>
                                <p className="text-sm">per token</p>
                            </button>
                        </div>
                        <button onClick={()=>handlePurchase()} className="w-28 text-center text-white text-sm hover:bg-red-500 hover:-translate-y-1 hover:shadow-lg bg-red-1 mb-5 rounded-md py-1 shadow-md transition duration-300">Purchase</button>
                        <p className="text-xs text-neutral-400 text-center mb-1">*If you were to use 3 pickup tokens a month</p>
                    </div>
                );
    
            default:
                return null;
        }
    };
    


    if (!popupActive) return null;

    return (
        <div>
            <div className="z-40 fixed fade-in bottom-0 w-screen h-screen bg-black opacity-60" />
            <div className="z-50 fixed slide-in-left flex bottom-0 left-0 justify-center items-center h-screen w-[500px]">
                {/* Popup */}
                <div className="overflow-y-scroll no-scrollbar flex flex-col w-full h-full justify-top items-center py-12 bg-white">
                    {/* Title */}
                    <div className="flex flex-row items-center space-x-2 w-full px-4 mb-2">
                        <hr className="flex grow border-neutral-400" />
                        <p className="text-sm text-black italic">Buying a Pick-Up token for:</p>
                        <hr className="flex grow border-neutral-400" />
                    </div>
                    <p className="text-black text-2xl font-bold mb-8 w-4/5 text-center">{partner?.business_name}</p>
                    {/* Hours Section */}
                    <div className="shadow-[0_2px_4px_rgba(0,0,0,0.1)] flex flex-col rounded-md w-4/5 justify-center items-center py-4 mb-9 bg-white border border-neutral-400">
                        <p className="font-bold text-black mb-2">Pickup Hours:</p>
                        <p className="text-sm text-black"> Mon: <i>8 A.M. - 7 P.M.</i></p>
                        <p className="text-sm text-black"> Tues: <i>8 A.M. - 7 P.M.</i></p>
                        <p className="text-sm text-black"> Wed: <i>8 A.M. - 7 P.M.</i></p>
                        <p className="text-sm text-black"> Thu: <i>10 A.M. - 7 P.M.</i></p>
                        <p className="text-sm text-black"> Fri: <i>8 A.M. - 7 P.M.</i></p>
                        <p className="text-sm text-black"> Sat: <i>8 A.M. - 11 P.M.</i></p>
                        <p className="text-sm text-black"> Sun: <i>8 A.M. - 11 P.M.</i></p>
                    </div>
                    {/* Plans Section */}
                    <PlansContent partner={partner} selectedPlan={selectedPlan} handlePlanSelection={handlePlanSelection} />
                </div>
                {/* Minimize Button */}
                <button onClick={() => setPopupActive(false)} className="flex w-7 h-24 bg-white rounded-r-md justify-center items-center p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="h-6 w-6 rotate-90 fill-black mr-1 ">
                        <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" />
                    </svg>
                </button>
            </div>
        </div>
    );
});
